import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
    <title>Loader Logo</title>
    <g>
     <g transform="translate(200.000000, 250.000000)">
        <path id="B" 
          d="M305.624,47.937 C304.804,48.690 303.846,49.65 302.753,49.65 L257.843,49.65 L257.843,201.227 C257.843,202.458 257.433,203.448 256.613,204.201 C255.792,204.950 254.834,205.328 253.742,205.328 L204.115,205.328 C203.19,205.328 202.96,204.950 201.346,204.201 C200.593,203.448 200.219,202.458 200.219,201.227 L200.219,49.65 L155.308,49.65 C154.78,49.65 153.85,48.690 152.335,47.937 C151.582,47.188 151.207,46.265 151.207,45.169 L151.207,4.360 C151.207,3.130 151.582,2.139 152.335,1.386 C153.85,0.637 154.78,0.259 155.308,0.259 L302.753,0.259 C303.846,0.259 304.804,0.637 305.624,1.386 C306.445,2.139 306.855,3.130 306.855,4.360 L306.855,45.169 C306.855,46.265 306.445,47.188 305.624,47.937 ZM124.755,205.328 L4.379,205.328 C3.148,205.328 2.155,204.950 1.405,204.201 C0.652,203.448 0.277,202.458 0.277,201.227 L0.277,4.360 C0.277,3.130 0.652,2.139 1.405,1.386 C2.155,0.637 3.148,0.259 4.379,0.259 L124.755,0.259 C125.847,0.259 126.805,0.637 127.626,1.386 C128.446,2.139 128.856,3.130 128.856,4.360 L128.856,45.169 C128.856,46.265 128.446,47.220 127.626,48.40 C126.805,48.860 125.847,49.270 124.755,49.270 L57.902,49.270 L57.902,78.390 L115.732,78.390 C116.824,78.390 117.747,78.768 118.500,79.518 C119.250,80.271 119.628,81.194 119.628,82.287 L119.628,123.301 C119.628,124.396 119.250,125.319 118.500,126.69 C117.747,126.822 116.824,127.197 115.732,127.197 L57.902,127.197 L57.902,156.522 L124.755,156.522 C125.847,156.522 126.805,156.900 127.626,157.650 C128.446,158.403 128.856,159.326 128.856,160.418 L128.856,201.227 C128.856,202.458 128.446,203.448 127.626,204.201 C126.805,204.950 125.847,205.328 124.755,205.328 Z"          fill="currentColor"
        />
       
        <circle cx="150" cy="100" r="300" id="Shape"
          stroke="currentColor"
          strokeWidth="50"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </g>
    </g>
      
  </svg>
);

export default IconLoader;
